import axios from 'axios'
import qs from "qs";

export const baseUrl = (process.env.NODE_ENV === 'development' ? 'https://pps.usayuq.com/index.php/' : 'https://pps.usayuq.com/index.php/')
axios.defaults.withCredentials = true;
class HttpRequest {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
  }
  interceptors (instance) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      var params=JSON.parse(sessionStorage.getItem('parmas'))
      if(config.method === 'post'){
      let data = qs.parse(config.data)
        config.data = qs.stringify({
          ...params,
          ...data,
          customer_id:sessionStorage.getItem('customer_id')||10000001,
          dealer_id:sessionStorage.getItem('dealer_id')||''
        })
      }else{
        config.params = {
          ...params,
          ...config.params,
          customer_id:sessionStorage.getItem('customer_id')||10000001,
          dealer_id:sessionStorage.getItem('dealer_id')||''
        }
      }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      const { data, status } = res
      if (data.code < 0) {
        throw new Error(data.msg)
      } else {
        return { data, status }
      }
    }, error => {
      return Promise.reject(error)
    })
  }
  request (options) {
    const instance = axios.create()
      options = Object.assign({
        baseURL: this.baseUrl,
      }, options)
    this.interceptors(instance)
    return instance(options)
  }
}
const api_axios = new HttpRequest(baseUrl)
export default api_axios